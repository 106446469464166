<template>
  <div id="msg">
    <img alt="Vue logo" src="./assets/logo.png">
    <div>此页面基于vue3构建</div>
    <br/>
    <!-- <br/><br/> -->
    <div class="content">
      <div class="desc">前端经典案例</div>
      <ul class="demo">
        <li v-for="(webpage,index) in webpages" :key="index"><a :href="webpage.path">{{webpage.title}}</a></li>
        <!-- 
        <li><a href="cssdemo/love520.html">告诉你一个秘密</a></li>
        <li><a href="cssdemo/cherry.html">圣诞树</a></li>
        <li><a href="cssdemo/guoqi/index.html">国旗头像</a></li>
        <li><a href="cssdemo/taiji.html">太极图</a></li>
        <li><a href="cssdemo/phone/vvv.html">手机看视频</a></li>
        <li><a href="cssdemo/phone/img_swiper.html">手机轮播图</a></li>
       -->
        <li><a href="#">待更新...</a></li>
      </ul>
    </div>
    <div class="root">前端技术交流QQ群:284462306</div>
    <router-view />
    <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: #5c6b77">
      网站备案号: <a target="_blank" href="https://beian.miit.gov.cn/">豫ICP备2022009114号-2</a>
    </div>
  </div>
  <!-- 
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  -->
</template>


<script>
//import HelloWorld from './components/HelloWorld.vue'
//import pages from './static/pages.json'
export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  data(){
    return {
        webpages:[{
          title:'圣诞树',
          path:'/cssdemo/cherry.html'
        }]
    }
  },
  methods: {

  },
  mounted(){
    //this.webpages = pages;
    let that = this;
    fetch('./static/pages.json').then(res=>{
         let jsons = res.json();
         console.log(jsons)
         return jsons
     }).then(e=>{
        console.log(e, '')
        that.webpages = e
     })
    
  }

}
</script>

<style>

* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

body{
  background-color: whitesmoke;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.content {
  background-color: #d1deeb;
  width: 80%;
  height: 350px;
  margin: 0 auto;
}

.desc {
  padding-top: 10px;
  
}

ul {
  padding-top: 20px;
}

ul li {
  float: left;
  width: 45%;
  height: 50px;
  line-height: 50px;
}

.root {
  position: absolute;
  background-color: beige;
  text-align: center;
  line-height: 50px;
  top: 10px;
  right: 20px;
  width: 300px;
  height: 50px;
}

</style>
